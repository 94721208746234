<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Coldrun` : `Coldrun | Software developers`
    }}</template>
  </metainfo>
  <div class="container">
    <router-view />
  </div>
</template>
<script>
import { useMeta } from 'vue-meta';

export default {
  setup() {
    useMeta({
      title: '',
      htmlAttrs: {
        lang: 'en',
      },
    });
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
};
</script>
