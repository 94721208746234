import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/jobs/front-end-developer-vue',
    name: 'Front-end Developer (Vue)',
    component: () =>
      import(
        /* webpackChunkName: "front-end-developer-vue" */ '../views/job/front-end-developer-vue.vue'
      ),
  },
  {
    path: '/jobs/junior-front-end-developer-vue',
    name: 'Junior Front-end Developer (Vue)',
    component: () =>
      import(
        /* webpackChunkName: "junior-front-end-developer-vue" */ '../views/job/junior-front-end-developer-vue.vue'
      ),
  },
  {
    path: '/jobs/ui-designer-freelance',
    name: 'UI Designer (Freelance)',
    component: () =>
      import(
        /* webpackChunkName: "ui-designer-freelance" */ '../views/job/ui-designer-freelance.vue'
      ),
  },
  {
    path: '/jobs/back-end-developer-net',
    name: 'Back-end Developer (.NET)',
    component: () =>
      import(
        /* webpackChunkName: "back-end-developer-net" */ '../views/job/back-end-developer-net.vue'
      ),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
